import * as angular from "angular";
import * as _ from "underscore";
import { SimpleChange } from "../../../TypeDefinitions/angularextensions";
import { UrlTokens } from "@rhinestone/portal-web-react";
import { SearchService } from "../search.service";
import { ISearchResponse } from "../searchResponse";
import { IResultLocation } from "./../IResultLocation";
import { UserFeaturesService } from "./../../User/userFeatures.service";
import {
  Criteria,
  CriteriaChangeType,
  CriteriaChange,
  Feature
} from "@rhinestone/portal-web-react";
import { SearchViewType, OrderingOption } from "@rhinestone/portal-web-api";
import { ResizeSensor } from "css-element-queries";
import { IMediaService, ScreenSize } from "../../Layout/media.service";
import { IPortalConfig } from "../../Core/portal.provider";
import moment = require("moment");

export class SearchResultController {
  /* Input bindings */
  public isLoading: boolean;
  public hasError: boolean;
  public errorMessage: string;
  public result: ISearchResponse;
  public criteria: ReadonlyArray<Criteria>;
  public resultView: SearchViewType;
  public isAuthenticated: boolean;
  public onUpdateCurrentResult: (outputParams: {
    resultLocation: IResultLocation;
  }) => void;
  public onCriteriaChanged: (outparams: { action: CriteriaChange }) => {};

  /* Internal state */
  public pageCount: number;
  public resultTemplate: string;
  public currentPage: number = 1;
  public descending: boolean = false;
  public resultPaneSize: ScreenSize;
  private resultPane: HTMLElement;

  public isViewSizeXs: boolean;
  public isViewSizeSm: boolean;
  public hasResults: boolean = false;
  public queryHasBeenExecuted: boolean = false;
  public currentOrder: OrderingOption;
  public visibleSlices: string[] =
    JSON.parse(window.sessionStorage.getItem("visibleSlices")) || [];
  private resizeSensor: ResizeSensor;

  public static $inject = [
    "$scope",
    "$element",
    "$location",
    "$translate",
    "searchService",
    "mediaService",
    "userFeaturesService",
    "portal",
    "$window"
  ];

  constructor(
    private $scope: angular.IScope,
    private $element: angular.IRootElementService,
    private $location: angular.ILocationService,
    private $translate: angular.translate.ITranslateService,
    private searchService: SearchService,
    private mediaService: IMediaService,
    private userFeaturesService: UserFeaturesService,
    private portal: IPortalConfig,
    private $window: angular.IWindowService
  ) {}

  public async $onInit() {
    this.deleteLegacyLastVisitedLocalStorage();
    this.resultPane = this.$element.find("#searchPageResults").get(0);
    this.updateViewSize();
    this.resizeSensor = new ResizeSensor(this.resultPane, () => {
      this.updateViewSize();
    });

    this.$scope.$on("$destroy", () => {
      ResizeSensor.detach(this.resultPane);
      this.resultPane = undefined;
    });
  }

  public $onChanges(changes: {
    result?: SimpleChange<ISearchResponse>;
    loading?: SimpleChange<boolean>;
    resultView?: SimpleChange<SearchViewType>;
    criteria?: SimpleChange<Criteria[]>;
  }) {
    if (changes.loading) {
      this.loadingStateChanged(changes.loading.currentValue);
    }

    if (changes.result) {
      this.updateSearchResult(changes.result.currentValue);
    }

    if (changes.resultView) {
      this.onNewResultViewSet(changes.resultView.currentValue);
    }

    if (changes.criteria) {
      this.criteria = changes.criteria.currentValue;
    }
  }

  // Note: We store the last visited date in local storage, as a single object, with the documentId as key.
  // To avoid Cookiebot listing all keys, and being able to manually categorize the as "necessary"
  public getLastVisited(documentId: string) {
    const key = "lastVisited";
    const lastVisitedString = localStorage.getItem(key);
    if (!lastVisitedString) return null;

    const lastVisited = JSON.parse(lastVisitedString);
    const lastVisitedDocument = lastVisited[documentId];
    if (!lastVisitedDocument) return null;

    const lastVisitedDocumentDate = moment(lastVisitedDocument);
    if (!lastVisitedDocumentDate.isValid()) return null;

    return lastVisitedDocumentDate.fromNow();
  }

  // TODO: Legacy code, remove when you come across this
  // Removes old keys in local storage, used per document
  public deleteLegacyLastVisitedLocalStorage() {
    const keysToRemove = Object.keys(localStorage).filter(key =>
      key.startsWith("lastVisited_")
    );

    keysToRemove.forEach(key => {
      localStorage.removeItem(key);
    });
  }

  private updateViewSize(): void {
    // To counteract the minimum distance to screen-edges and to get the Sm Xs Md to actually
    // correspond somewhat to the device used, we subtract the size of the side-panes.
    // This system is very brittle as it has logic bound to custom styling..
    // TODO: Re-implement using Css-classes bound to have parents "left-side-pane-show" etc.
    const COLLAPSED_SIDE_PANES_WIDTH = 150;

    const newSize = this.mediaService.getScreenSizeFromPixels(
      this.resultPane.clientWidth + COLLAPSED_SIDE_PANES_WIDTH
    );

    if (newSize !== this.resultPaneSize) {
      this.resultPaneSize = newSize;
      this.isViewSizeSm = newSize === ScreenSize.Sm;
      this.isViewSizeXs = newSize === ScreenSize.Xs;
      this.$scope.$apply();
    }
  }

  public loadingStateChanged(isLoading: boolean) {
    if (isLoading) {
      this.hasResults = false;
    }
  }

  public toggleSliceVisibility(documentId: string): void {
    const index = this.visibleSlices.indexOf(documentId);
    if (index === -1) {
      this.visibleSlices.push(documentId);
    } else {
      this.visibleSlices.splice(index, 1);
    }
    window.sessionStorage.setItem(
      "visibleSlices",
      JSON.stringify(this.visibleSlices)
    );
  }

  public getMainSliceTitle(result: any): string {
    if (result.SliceNumber == 0) return "";
    if (angular.isDefined(result.Slices)) {
      const found = _(result.Slices).find(
        (x: any) => x.SliceKey === result.SliceKey
      );
      if (angular.isDefined(found)) {
        return this.getSliceTitle(found);
      }
    }
    // If main slice title is not found, leave it blank. (It's probably a document slice).
    return "";
  }

  public getSliceTitle(slice: any): string {
    if (slice.SliceTitleHighlighted) {
      return slice.SliceTitleHighlighted;
    }

    if (slice.SliceTitle) {
      return slice.SliceTitle;
    }

    if (slice.SliceTitleKey) {
      return this.$translate.instant(slice.SliceTitleKey);
    }

    return "";
  }

  public getMainSliceSnippets(result: any): string[] {
    let returnValue: string[] = [];
    // Document slices should not have snippets
    if (result.SliceNumber == 0) return [];
    if (result.Snippets && result.Snippets.length > 0) {
      returnValue = result.Snippets;
    } else if (result.Slices && result.Slices.length > 0) {
      // If the document does not have "main" snippets, use the snippets from the
      // first slice
      returnValue = result.Slices[0].Snippets;
    }
    return returnValue;
  }

  public getMainSliceBookMark(result: any): string {
    if (angular.isDefined(result.Slices)) {
      const found = _(result.Slices).find(
        (x: any) => x.SliceKey === result.SliceKey
      );
      if (angular.isDefined(found)) {
        return found.SliceBookmark;
      }
    }
    return "";
  }

  public updateSearchResult(searchResponse: ISearchResponse) {
    if (!searchResponse) {
      this.resetResult();
    } else {
      this.onNewResponseSet(searchResponse);
    }
  }

  public addTaxonToCriteria(providerKey: string, taxonKey: string): void {
    this.onCriteriaChanged({
      action: {
        changes: [
          {
            type: CriteriaChangeType.Add,
            criteria: {
              providerKey,
              data: taxonKey
            }
          }
        ]
      }
    });
  }

  public resetResult() {
    this.queryHasBeenExecuted = false;
    this.pageCount = null;
    this.currentPage = 1;
    this.descending = false;
    this.hasResults = false;
    this.visibleSlices =
      JSON.parse(window.sessionStorage.getItem("visibleSlices")) || [];
  }

  public onNewResponseSet(searchResponse: ISearchResponse) {
    this.queryHasBeenExecuted = true;
    this.pageCount = Math.ceil(
      searchResponse.TotalCount / this.resultView.defaultPageSize
    );
    this.hasResults = searchResponse.TotalCount > 0;

    this.currentPage = searchResponse.PageNumber;
    this.currentOrder =
      _(this.resultView.orderingOptions).find(
        (x: OrderingOption) => x.fieldName === searchResponse.SortName
      ) || this.resultView.orderingOptions[0];
    this.descending = searchResponse.Descending;
  }

  public onNewResultViewSet(resultView: SearchViewType) {
    if (resultView == null) {
      return;
    }

    this.resultTemplate = `/templates/${resultView.templateName}`.replace(
      "//",
      "/"
    );
    this.currentOrder =
      _(resultView.orderingOptions).find(
        (x: OrderingOption) =>
          x.fieldName === this.resultView.defaultOrderingOption
      ) || resultView.orderingOptions[0];
    this.descending = this.currentOrder.defaultDirectionDescending;
  }

  private openDocument($event: MouseEvent, documentUrl: string): void {
    $event.preventDefault();

    // Pass search criteria from query string to document page. This will copy the state to React to highlight search phrases
    const search = this.$location.search();
    const newSearchValue = {
      [UrlTokens.search]: search[UrlTokens.search],
      [UrlTokens.showExact]: "true"
    };

    if ($event.ctrlKey === true) {
      const newSearchParams = new URLSearchParams(newSearchValue);
      this.$window.open(
        documentUrl + "?" + newSearchParams.toString(),
        "_blank"
      );
    } else {
      this.$location.url(documentUrl).search(newSearchValue);
    }
  }

  public openMainDocument($event: MouseEvent, result: any): void {
    this.openDocument($event, this.getMainDocumentUrl(result));
  }

  public openSlice($event: MouseEvent, result: any, slice: any): void {
    this.openDocument(
      $event,
      `${this.getMainDocumentUrl(result)}#${slice.SliceBookmark}`
    );
  }

  public openMainSlice($event: MouseEvent, result: any): void {
    this.openDocument(
      $event,
      `${this.getMainDocumentUrl(result)}#${this.getMainSliceBookMark(result)}`
    );
  }

  private getMainDocumentUrl(result: any): string {
    return `/h/${result.HiveId}/${result.FullName}`;
  }

  public changeOrdering(fieldName: string) {
    this.onUpdateCurrentResult({
      resultLocation: {
        pageNumber: 1,
        sortName: fieldName,
        sortDescending: !this.descending
      }
    });
  }

  public orderBySelected = (): void => {
    const fieldName = this.currentOrder.fieldName;
    const defaultDirectionDescending =
      this.currentOrder.defaultDirectionDescending;

    this.onUpdateCurrentResult({
      resultLocation: {
        pageNumber: 1,
        sortName: fieldName,
        sortDescending: defaultDirectionDescending
      }
    });
  };

  public reverseOrder = (): void => {
    const fieldName = this.currentOrder.fieldName;

    this.onUpdateCurrentResult({
      resultLocation: {
        pageNumber: 1,
        sortName: fieldName,
        sortDescending: !this.descending
      }
    });
  };

  public isSelected(
    fullName: string,
    hiveId: string,
    revisionId: number
  ): boolean {
    return this.searchService.isSelected(fullName, hiveId, revisionId);
  }

  public toggleDocumentSelection(
    fullName: string,
    hiveId: string,
    revisionId: number
  ): void {
    return this.searchService.toggleDocumentSelection(
      fullName,
      hiveId,
      revisionId
    );
  }

  public hasAssetCollectionsFeature(): boolean {
    return this.userFeaturesService.hasFeature(Feature.AssetCollection);
  }
}

angular
  .module("PortalApp")
  .controller("Rhinestone.SearchResultController", SearchResultController);
